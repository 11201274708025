import React, { Component } from 'react';
import { Jumbotron } from 'react-bootstrap';
import { CSSTransitionGroup } from 'react-transition-group'
import { HTMLVis } from './htmlvis';

class Course extends Component{
  render(){
    return(
      <tr className = {this.getColor()} onClick={(e)=>{
        e.cancelBubble = true;
        if (e.stopPropagation) e.stopPropagation();
        this.props.openSubmition(this.props.obj)
      }}>
        <td>{this.getTeachers()}</td>
        {this.getTimes()}
        {/*<td>{this.props.free + "/" + this.props.total}</td>*/}
      </tr>
    );
  }
  getTeachers(){
    let ans = "";
    for (let t of this.props.obj.teachers){
      ans += t + ", ";
    }
    return ans.substring(0, ans.length-2);
  }
  getTimes(){
    let ans = [];
    for (let i=0; i<this.props.obj.times.length; ++i){
      ans.push(<td colSpan={6/this.props.obj.times.length} key={i}>{this.props.obj.times[i]}</td>);
    };
    return ans;
  }
  getColor(){
    if (this.props.obj.free <= 0) return "danger";
    if (this.props.obj.free <= this.props.obj.total/2) return "warning";
    return "success"; 
  }
}

class CourseTable extends Component{
  render(){
    return(
      this.props.courses.reduce((acc, curr) => {return acc+curr.free}, 0) > 0 ?
      <table className="table table-bordered table-hover table-responsive" style={{textAlign: 'center'}}>
        <thead>
          <tr>
            <th style={{textAlign: 'center'}}>Преподавател</th>
            <th colSpan={6} style={{textAlign: 'center'}}>Часове на провеждане</th>
            {/*<th style={{textAlign: 'center'}}>Свободни места</th>*/}
          </tr>
        </thead>
        <tbody>
          {this.props.courses.map((x, ind)=>{
            return x.free>0 ? <Course key={ind} obj={x} openSubmition={this.props.openSubmition}/> : null;
          })}
        </tbody>
      </table> :
      <p>Няма свободни места!</p>
    );
  }
}

class CourseType extends Component{
    constructor(props){
        super(props);
        this.state = {extended: false}
        console.log(this.props.courses);
    }
    onclick(){
        this.setState({extended: !this.state.extended});
    }
    getInfo(){
        if (!this.state.extended) return [<p key={1}>Кликнете за повече информация</p>];
        return [
            <div key={1}>
                <HTMLVis html={this.props.obj.description} instructions={0}/>
                <HTMLVis html={this.props.obj.instructions} instructions={1}/>
            </div>
        ];
    }
    render(){
        return(
            <Jumbotron onClick={this.onclick.bind(this)}>
                <h3>{this.props.obj.title}</h3>
                <CourseTable courses={this.props.obj.courses} openSubmition={this.props.openSubmition}/>
                <CSSTransitionGroup transitionName="info" transitionEnterTimeout={200} transitionLeaveTimeout={200}>
                    {this.getInfo()}
                </CSSTransitionGroup>
            </Jumbotron>
        );
    }
}

class CourseYear extends Component{
    types(){
        let ans = []
        for (let i=0; i<this.props.courseTypes.length; ++i){
            ans.push( <CourseType key={i} obj={this.props.courseTypes[i]} openSubmition={this.props.openSubmition}/> );
        }
        return ans;
    }
    render(){
        return(
        <div className="container">
          <Jumbotron>
          <p>
            За учебната 2023/2024 година Сикадеми предоставя избор на родителите за присъствено, или онлайн обучение на записаните ученици.
            Занятията ще се провеждат едновременно за всички ученици от групата. 
          </p>
          <p>Когато учениците учат дистанционно, курсовете ще бъдат провеждани онлайн според обявения график!</p>
          <p>Курсовете в Сикадеми надграждат училищния материал.</p>
          </Jumbotron>
            {this.types()}
        </div>
        );
    }
}

/*
class PopupInfo extends Component{
  constructor(props){
    super(props);
    this.state = {popup: false};
  }
  onclick(){
    this.setState({popup: !this.state.popup});
  }
  render(){
    return(
      <div onClick={this.onclick.bind(this)}>
        {this.props.info}
        {this.state.popup ? this.getPopup(): null}
      </div>
    );
  }
  getPopup(){
    if (!this.state.popup) return [];
    return (
      <div>{this.props.popupinfo}</div>
    );
  }
}

class NextYearInfo extends Component{
    constructor (props){
      super(props);
      this.state = {courses: []};
      subscribeNextCourses((x)=>{
        this.setState({courses: this.state.courses.concat([x])});
      }, (courses_)=>{
        this.setState({courses: courses_});
      })
    }
    render(){
      return(
        <div className='container'>
          <Jumbotron>
            <h2>Курсове по математика за учебната 2018/2019</h2>
            <h3>Подготвителни курсове за кандидатстване за 2-ри клас</h3>
            <CourseTable courses={this.state.courses.filter(x=>x.name[0]==='2')} openSubmition={this.props.openSubmition} />
            <h3>Подготвителни курсове за кандидатстване за 3-ти клас</h3>
            <CourseTable courses={this.state.courses.filter(x=>x.name[0]==='3')} openSubmition={this.props.openSubmition} />
            <h3>Подготвителни курсове за кандидатстване за 4-ти клас</h3>
            <CourseTable courses={this.state.courses.filter(x=>x.name[0]==='4')} openSubmition={this.props.openSubmition} />
            <h3>Подготвителни курсове за кандидатстване за 6-ти клас</h3>
            <CourseTable courses={this.state.courses.filter(x=>x.name[0]==='6')} openSubmition={this.props.openSubmition} />
            <h3>Подготвителни курсове за кандидатстване за 7-ми клас</h3>
            <CourseTable courses={this.state.courses.filter(x=>x.name[0]==='7')} openSubmition={this.props.openSubmition} />
            <h3>Състезателни курсове за 5-ти клас</h3>
            <CourseTable courses={this.state.courses.filter(x=>x.name[0]==='5')} openSubmition={this.props.openSubmition} />
            <h3>Състезателни курсове за подготовка за Младежката балканиада по математика за 7, 8 и 9-ти клас</h3>
            <CourseTable courses={this.state.courses.filter(x=>x.name[0]==='М')} openSubmition={this.props.openSubmition} />
            <h3>Онлайн състезателни курсове за подготовка за Младежката балканиада по математика за 7, 8 и 9-ти клас</h3>
            <CourseTable courses={this.state.courses.filter(x=>x.name[0]==='О')} openSubmition={this.props.openSubmition} />
            <h3>Допълнителна информация</h3>
            <ul>
              <li>Малки групи от до 9 деца.</li>
              <li>Занимания веднъж седмично в часовете посочени в таблиците.</li>
              <li>Цена за модул: 250лв.</li>
            </ul>
            <h3>Обучение</h3>
            <ul>
              <li>Всеки модул включва 10 занятия, в които целта ни е да подготвим младите математици за кандидатстване след 4-ти клас, а в състезателните курсове да подготвим учениците за успешно представяне на състезания по математика.</li>
              <li>За допълнителна информация може да се свържете с нас.</li>
            </ul>
            <h3>Записване</h3>
            <p>За да се запишете за курс по математика изпълнете следните стъпки:</p>
            <ol>
              <li>Кликнете върху курса по-горе. Ще се отвори прозорче в което да попълните Вашите данни.</li>
              <li>Попълнете Вашите данни и кликнете "Запиши ме".</li>
              <li>След това, очаквайте да се свържем с Вас за потвърждение в рамките на 1-2 дни.</li>
            </ol>
          </Jumbotron>
          <Jumbotron>
            <h2>Курсове по програмиране за учебната 2018/2019 година</h2>
            <h3>Програмиране за начинаeщи на scratch за 2-ри и 3-ти клас</h3>
            <CourseTable courses={this.state.courses.filter(x=>x.name[0]==='s')} openSubmition={this.props.openSubmition} />
            <h3>Състезателно програмиране за начинаещи за 4-ти и 5-ти клас</h3>
            <CourseTable courses={this.state.courses.filter(x=>x.name[0]==='c')} openSubmition={this.props.openSubmition} />
            <h3>Разработка на HTML5 игри за начинаeщи за 4-ти, 5-ти и 6-ти клас</h3>
            <CourseTable courses={this.state.courses.filter(x=>x.name[0]==='h')} openSubmition={this.props.openSubmition} />
            <h3>Разработка на C++ игри за начинаещи за 7-ми и 8-ми клас</h3>
            <CourseTable courses={this.state.courses.filter(x=>x.name[0]==='g')} openSubmition={this.props.openSubmition} />
            <h3>Разработка на full stack приложения за начинаещи за 7-ми и 8-ми клас</h3>
            <CourseTable courses={this.state.courses.filter(x=>x.name[0]==='f')} openSubmition={this.props.openSubmition} />
            <h3>Допълнителна информация</h3>
            <ul>
              <li>Малки групи от до 9 деца.</li>
              <li>Занимания веднъж седмично в часовете посочени в таблиците.</li>
              <li>Осигурени компютри с инсталиран необходимия софтуер при желание.</li>
              <li>Отстъпка 50лв на модул ако ученика ползва собствен лаптоп.</li>
              <li>Цена за модул: 300лв (250лв ако ученика ползва собствен лаптоп).</li>
            </ul>
            <h3>Обучение</h3>
            <ul>
              <li>Всеки модул включва 10 занятия, в които целта ни е да научим учениците на основни познания в областта на програмирането и по-напреднали познания в конкретна област. За повече подробности за всеки курс погледнете в допълнителна информация по-долу.</li>
              <li>За допълнителна информация може да се свържете с нас.</li>
            </ul>
            <h3>Записване</h3>
            <p>За да се запишете за курс по програмиране изпълнете следните стъпки:</p>
            <ol>
              <li>Кликнете върху курса по-горе. Ще се отвори прозорче в което да попълните Вашите данни.</li>
              <li>Попълнете Вашите данни и кликнете "Запиши ме".</li>
              <li>След това, очаквайте да се свържем с Вас за потвърждение в рамките на 1-2 дни.</li>
            </ol>
            <p>Допълнителна информация за курсовете по програмиране (кликнете върху курса по долу):</p>
            <ul>
              <li><PopupInfo info="Програмиране за начинаещи на scratch за 2-ри и 3-ти клас" popupinfo="В този курс ще запознаем децата с основите на програмирането посредством езика scratch. Този език е разработен именно за малки деца с обучителна цел. С него ще правим различни анимации и прости игри."/></li>
              <li><PopupInfo info="Състезателно програмиране за начинаещи за 4-ти и 5-ти клас" popupinfo="В този курс ще запознаем децата с основите на състезателното програмиране. Това ще позволи на децата да участват на състезанията по информатика в страната. Този курс е подходящ и за по-големи, които не са се занимавали с програмиране до момента."/></li>
              <li><PopupInfo info="Разработка на HTML5 игри за начинаeщи за 4-ти, 5-ти и 6-ти клас" popupinfo="В този курс ще научим децата да програмират, правейки прости компютърни игри по забавен и интригуващ начин. Този курс е подходящ и за по-големи, които не са се занимавали с програмиране до момента."/></li>
              <li><PopupInfo info="Разработка на C++ игри за начинаещи за 7-ми и 8-ми клас" popupinfo="В този курс ще научим учениците да програмират на C++ и посредством него да правят компютърни игри. Курса ще даде добро разбиране за разработката на приложения на C++. Курса е подходящ и за по-големи, които не са се занимавали с програмиране до момента."/></li>
              <li><PopupInfo info="Разработка на full stack приложения за начинаещи за 7-ми и 8-ми клас" popupinfo="В този курс ще научим учениците да програмират и създават web приложения с много различни вградени функционланости. Ще се изучават MySQL бази данни, Node.js сървър, HTML, JavaScript и CSS. Материала ще бъден представен по прост и атрактивен начин. В края на курса учениците ще могат да правят сайтове като този. Курса е подходящ и за по-големи, които не са се занимавали с програмиране."/></li>
            </ul>
            <p>За всеки от курсовете по програмиране не се изискват предварителни знания по програмиране, като може да се записват и по-големи ученици от препоръчаното.</p>
          </Jumbotron>
        </div>
      );
    }
}
*/

export { CourseYear };