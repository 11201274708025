import React, { Component } from 'react';
import { Jumbotron } from 'react-bootstrap';
import { subscribeNews, format } from "./connection.js"

class News extends Component{
  render(){
    return (
      <Jumbotron>
        <h2>{this.props.title}</h2>
        <p>{this.genContentLinks()}</p>
        <p>{this.props.date}</p>
      </Jumbotron>
    );
  }
  genContentLinks(){
    let ans = [];
    try{
      let curr = 0;
      for (let i=0; i<this.props.content.length; ++i){
        let c = this.props.content[i];
        if (c === '*'){
          let linkTo = "";
          ++i;
          for (++i; this.props.content[i] !== '\''; ++i){
            linkTo += this.props.content[i];
          }
          let text = ""
          for (++i; this.props.content[i] !== '*'; ++i){
            text += this.props.content[i];
          }
          ans.push(<a key={curr++} onClick={()=>{this.props.linkcb(linkTo)}}>{text}</a>);
        }else{
          let text = "";
          for (; i<this.props.content.length && this.props.content[i] !== '*'; ++i){
            text += this.props.content[i];
          }
          ans.push(text);
          if (i<this.props.content.length) --i;
        }
      }
      return ans;
    }catch(err){
      return "Не са наред кавичките или *-ките!";
    }
  }
}

class NewsList extends Component {
  render() {
    return (
      <div className="container">
        <Jumbotron>
          <h3>Втори кръг математическо състезание „ Ключът към знанието“</h3>
          <h3>Резултати от състезанието:</h3>
          <p><a href="https://drive.google.com/drive/u/2/folders/1yYBLw40qOWZeEsvUA9GM5VbycfJM7gpb">Класиране</a> на всички участници.</p>
          <p>Разглеждане на работите на 17.06 след 13:00 в офиса на бул. Христо Смирненски 9 след уговорка. За да се уговорите с првподавател, моля пишете на stennli@sicademy.bg</p>
          <h3>Теми от състезанието:</h3>
          <p>Темите от състезанието може да намерите <a href="https://drive.google.com/drive/folders/1v8XO81seujjUyuNDnIBmkObwR8xZkGsF?usp=share_link">тук</a>.</p>
          <p>Пълни и подробни решения и описания на задачите може да разгледате в нашия <a href="https://www.youtube.com/@SicademyBG">ютюб канал</a>.</p>
          <p>Не забравяйте да се абонирате!</p>
          <p>За още актуални новини следете <a href="https://facebook.com/SIcademy">нашата фейсбук страница!</a></p>
          <p>Резултатите от 3 и 4 клас ще бъдат обявени не по-късно от 16 юни 2023 на фейсбук страницата!</p>
          <p></p>
          <p><span style={{color:"red"}}>Внимание</span>: Втори кръг на сътсезанието за учениците от 3 и 4 клас ще се проведе на 10.06.2023 от 09:00 в НПМГ. Молим всички желаещи за участие да се запишат през <a href="https://docs.google.com/forms/d/e/1FAIpQLSdErucJlVLuSgxq42_vFbizZkVfchiBaJUW78kiRrdnknU_tA/viewform">посочената форма</a>.</p>
          <p>Състезанието от 5 до 11 клас ще се проведе на 17.6.2023 от 09:00 в НПМГ. Желаещите за участие е необходимо да попълнят следната <a href="https://docs.google.com/forms/u/2/d/1LnORAzGA711vJzzlwYUZQQ22bG8-GfX3zfQRQTjHEJI/edit?usp=forms_home&ths=true">форма</a>.</p>
          <p>Резултатите ще бъдат обявени до 26.06.2023 г.</p>
          <p>Във всяка възрастова група от 3 до 7 клас, наградени с медали ще бъдат първите 50% от участниците във втори кръг на състезанието.</p>
          <p>Съотношението на Първи, Втори и Трети награди ще бъде приблизително 1:2:3 за всяка възрастова група от 3 до 7 клас.</p>
          <p>Във възрастовата група от 8 до 11 клас, наградите ще бъдат съответно: първо, второ и трето място според изкараните точки.</p>
          <p>Всички участници с равни точки ще получат еднакви награди!</p>
          <p>Участниците без награди ще получат Грамоти за участие в Първи математически турнир „ Ключът към знанието“.</p>

        </Jumbotron>
        <Jumbotron>
          <h3>СИкадеми има удоволствието да Ви представи
              Първи математически турнир „Ключът към знанието“!
          </h3>
          <p><span style={{color:"red"}}>Внимание</span>: Втори кръг на сътсезанието за учениците от 3 и 4 клас ще се проведе на 10.06.2023 от 09:00 в НПМГ. Молим всички желаещи за участие да се запишат през <a href="https://docs.google.com/forms/d/e/1FAIpQLSdErucJlVLuSgxq42_vFbizZkVfchiBaJUW78kiRrdnknU_tA/viewform">посочената форма</a>.</p>
          <p>Състезанието от 5 до 11 клас ще се проведе на 17.6.2023 от 09:00 в НПМГ. Желаещите за участие е необходимо да попълнят следната <a href="https://docs.google.com/forms/u/2/d/1LnORAzGA711vJzzlwYUZQQ22bG8-GfX3zfQRQTjHEJI/edit?usp=forms_home&ths=true">форма</a>.</p>
          <h3>Резултати и участие във втори кръг:</h3>
          <p>Резултати от Първи кръг на математическо състезание „ Ключът към знанието“</p>
          <p>1. <a href="https://drive.google.com/drive/folders/1El5WuPY8d-ohTBuJ2LbpTYDKTlKvVxN8?usp=share_link">Класиране</a> на всички участници</p>
          <p>2. <a href="https://drive.google.com/drive/folders/1RoasBaG8_YUfBIYmCI786kcNPvYtzU_p?usp=share_link">Допуснати</a> до 2 кръг, който ще се проведе на 17.06.2023 г. от 9.00 ч. в НПМГ гр. София</p>
          <p>За групите от пети и седми клас са класирани всички участници, които са изкарали поне 50% от точките на първия в класирането (заради малкия брой участници в тези групи).</p>

<p>Всеки ученик, некласиран за 2 кръг на Първи математически турнир „ Ключът към знанието“, може да вземе участие във 2 кръг, без да участва с резултата си в крайното класиране! </p>
<p>Резултатите на тези участници ще бъдат обявени отделно!</p>
<p>Всички желаещи да се включат във втори кръг е необходимо да преведат таксата за участие от 20лв. не по-късно от 15.06.2023 г.</p>

<p>Разглеждане на работите ще е възможно в дните и часовете, представени в следния <a href="https://drive.google.com/drive/folders/1fBoU58w3VOs1yl1XCnRZaUevItfdgR7y?usp=sharing">график</a> след предварителна заявка на имейл stennli@sicdemy.bg.</p>

          <h3>Теми от състезанието:</h3>
          <p>Темите от състезанието може да намерите <a href="https://drive.google.com/drive/folders/1v8XO81seujjUyuNDnIBmkObwR8xZkGsF?usp=share_link">тук</a>.</p>
          <p>Пълни и подробни решения и описания на задачите може да разгледате в нашия <a href="https://www.youtube.com/@SicademyBG">ютюб канал</a>.</p>
          <p>Не забравяйте да се абонирате!</p>
          <p>За още актуални новини следете <a href="https://facebook.com/SIcademy">нашата фейсбук страница!</a></p>
          <p>Резултатите от първия кръг ще бъдат обявени не по-късно от 12 май 2023 на фейсбук страницата!</p>
          <h3>Обща информация:</h3>
          <p>Състезанието ще се проведе в 2 кръга, насочено е към ученици с изявен интерес към математиката, и всички задачи ще бъдат с повишена трудност!</p>
          <p><b>Първи кръг</b> ще се проведе на 01.05.2023 г. от 9.00 в НПМГ Акад. Л. Чакалов. </p>
          <p>Такса правоучастие – 20 лв, която ще се заплаща само по банков път!</p>
          <p><b>Втори кръг</b> ще се проведе в средата на месец юни 2023 г. в НПМГ Акад. Л. Чакалов.</p>
          <p>Такса правоучастие - 20 лв.</p>
          <h3>Регламент:</h3>
          <p>Участниците ще бъдат разпределени в 9 възрастови групи по класове - 3, 4, 5, 6, 7, 8, 9, 10 и 11+:</p>
          <p><div style={{color:"red", display:"inline"}}>Групи 3 и 4 клас:</div> Състезанието ще бъде във формат на приемен изпит за прием в математически гимназии след 4 клас. Задачите са разпределени в два модула, както следва: </p>
          <p>Модул 1 съдържа 15 задачи с избираем отговор / по 1 т. за всеки верен отговор/, и 5 задачи с отворен отговор / по 2 т. за верен отговор/.</p>
          <p>Модул 2 се състои от 2 задачи по извънкласния материал за съответния клас. Изисква се подробно описване на решението, и всяка задача носи по 10 точки при пълно и точно описание. </p>
          <p>Максимален брой точки – 45.</p>
          <p>Времетраене: за модул 1 - 60 минути, за модул 2 – 1 час и 30 минути</p>
          <p><div style={{color:"red", display:"inline"}}>Групи 5, 6 и 7 клас:</div> Състезанието ще бъде във формат НВО след 7 клас.</p>
          <p>Модул 1 съдържа 18 задачи с избираем отговор/ по 3 т. за верен отговор/, и 2 задачи с по няколко отворени отговора /съответно по 5т и 6т/.</p>
          <p>Модул 2 се състои от 3 задачи изискващи подробно описание. Две от задачите се оценяват максимално с по 12 точки, а третата - с 11.</p>
          <p>Максимален брой точки – 100.</p>
          <p>Времетраене: за модул 1 - 60 минути, за модул 2 – 1 час и 30 минути.</p>
          <p><div style={{color:"red", display:"inline"}}>Групи 8, 9, 10, 11+ клас</div> – състезанието ще включва 6 подробни задачи.</p>
          <p>Всяка задача се оценява максимално с по 10 точки.</p>
          <p>Задачите включват материал от областите: Алгебра, Геометрия, Комбинаторика и Теория на числата. </p>
          <p>В групата 11+ клас могат да участват както ученици от 11 и 12 клас, така и всички любители на математическите предизвикателства / студенти, учители, ръководители на отбори/.</p>
          <p>За групата 11+ клас няма да бъдат включени задачи, които изискват изучаван в Университета материал, но всяко правилно прилагане на такива знания ще се приема за валидно</p>
          <p>Максимален брой точки – 60</p>
          <p>Времетраене - 4 часа.</p>
          <h3>Допускане до следващ кръг</h3>
          <p>За групите от 3 до 7 клас: За участие на 2 кръг  се допускат първите 50%  от всяка възрастова група.</p>
          <p>За групите от 8 до  11+ клас: Всеки участник, получил поне 15 точки.</p>
          <p><b>Записване:</b> Попълнете детайлите в посочената <a href="https://docs.google.com/forms/u/1/d/140AsLGUK4nLe7d4KrMqHtWU0D0prIkVSwFLmcVP_0v0/edit?usp=forms_home&ths=true" target="blank_">гугъл форма</a>.</p>
          <p>До два работни дни от записването ще получите имейл с потвърждение, както и номер на банкова сметка за плащане на таксата. </p>
          <p>В състезанието нямат право на участие лица, пряко свързани с дейността на СИкадеми.</p>
          <p>За връзка с нас: sicademymaths@gmail.com или тел: 0899856334.</p>
          <p>Очакваме Ви!</p>
          {/*<p>На датите 13.09, 14.09, 18.09 и 19.09 ще се проведе интензивен курс по състезателна математика, подходящ за ученици от 8 до 12 клас.</p>
          <p>Лекциите ще включват материал от четирите основни дисциплини - Алгебра, Геометрия, Теория на числата и Комбинаторика. Лектори на курса ще бъдат Станислав Димитров и Галин Тотев.</p>
          <p>Разпсисанието на лекциите и темите ще намерите в таблицата по-долу.</p>
          <table className="table table-bordered table-hover table-responsive" style={{textAlign: 'center', backgroundColor: 'white'}}>
            <thead>
              <tr>
                <th>Ден</th>
                <th>Час</th>
                <th>Преподавател</th>
                <th>Тема</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowSpan="2">Понеделник 13.09</td>
                <td>15:00-17:00</td>
                <td>Галин Тотев</td>
                <td>Алгоритми (К)</td>
              </tr>
              <tr>
                <td>17:30-19:30</td>
                <td>Галин Тотев</td>
                <td>Задачи за полиноми - част 1 (А)</td>
              </tr>
              <tr>
                <td rowSpan="2">Вторник 14.09</td>
                <td>15:00-17:00</td>
                <td>Галин Тотев</td>
                <td>Задачи за полиноми - част 2 (А)</td>
              </tr>
              <tr>
                <td>17:30-19:30</td>
                <td>Галин Тотев</td>
                <td>Каскади на Виет (ТЧ)</td>
              </tr>
              <tr>
                <td rowSpan="2">Събота 18.09</td>
                <td>15:00-17:00</td>
                <td>Станислав Димитров</td>
                <td>Теорема на Хол (К)</td>
              </tr>
              <tr>
                <td>17:30-19:30</td>
                <td>Станислав Димитров</td>
                <td>Неравенства между претеглени средни (А)</td>
              </tr>
              <tr>
                <td rowSpan="2">Неделя 19.09</td>
                <td>15:00-17:00</td>
                <td>Станислав Димитров</td>
                <td>Радикални оси (Г)</td>
              </tr>
              <tr>
                <td>17:30-19:30</td>
                <td>Станислав Димитров</td>
                <td>Мултипликативни функции (ТЧ)</td>
              </tr>
            </tbody>
          </table>
          <p>Цена: 220 лева</p>
          <p>За записване и въпроси пишете на stennli@sicademy.bg.</p>*/}
        </Jumbotron>
      </div>
    );
  }
}

export {NewsList};