import React, { Component } from 'react';
import { subscribeYears, submitForm } from './connection';
import { Jumbotron, Navbar, Nav, NavItem, Carousel } from 'react-bootstrap';
import './App.css';
import './transitions.css';
import { CSSTransitionGroup } from 'react-transition-group'
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import { NewsList } from "./news"
import { SecondRound } from "./secondRound"
import { CourseYear } from "./courses"

const MyMapComponent = withScriptjs(withGoogleMap((props) =>
  <GoogleMap
    defaultZoom={15}
    defaultCenter={{ lat: 42.680835, lng: 23.331762 }}
  >
    {props.isMarkerShown && <Marker position={{ lat: 42.680835, lng: 23.331762 }} />}
  </GoogleMap>
))

class Teacher extends Component{
  constructor(props){
    super(props);
    this.state = {extended: true}
  }
  onclick(){
    this.setState({extended: !this.state.extended});
  }
  render(){
    return (
      <div style={{textAlign: "center"}} className="col-md-6" onClick={this.onclick.bind(this)}>
        <Jumbotron>
          <h3>{this.props.name}</h3>
          <img className="img-rounded" style={{width: "100%"}} alt="Снимка" src={this.props.photo}/>
          <CSSTransitionGroup transitionName="description" transitionEnterTimeout={200} transitionLeaveTimeout={200}>
            {this.getDescription()}
          </CSSTransitionGroup>
        </Jumbotron>
      </div>
    );
  }
  getDescription(){
    if (!this.state.extended) return [];
    return (
       <p style={{marginTop: "50px"}} key={1}>{this.props.description}</p>
    );
  }
}

class TeachersList extends Component{
  render(){
    return (
      <div className="container">
        <div className="row">
          <Teacher name="Станислав Димитров" photo={require("./images/stenli.jpg")} description="Станислав Димитров е дългогодишен състезател по математика, член на журито на математически състезания и съавтор на сборника 555 задачи по геометрия, както и английската версия 555 geometry problems. Изнася лекции пред националните отбори по математика и от две години преподава на ученици от 2-ри до 4-ти клас."/>
          <Teacher name="Иво Дилов" photo={require("./images/ivo.jpg")} description="Иво Дилов е дългогодишен състезател по информатика и математика с множество постижения на международно ниво. Член е на журито и научния комитет на състезания по информатика и математика. Има двугодишен опит в преподаването на деца като лектор в Telerik kids academy."/>
        </div>
        <div className="row">
          <Teacher name="Костадин Гаров" photo={require("./images/garov.jpg")} description="Костадин Гаров е състезател по математика и физика с отличия от национални и международни форуми. Има опит в работа с ученици, лектор на лагер-школи по математика с цел подготовка за олимпиади и състезания. Ръководител на един от българските отбори, участващи в IZhO '18."/>
          <Teacher name="Станислав Йорданов" photo={require("./images/jordanov.jpg")} description="Станислав Йорданов е дългогодишен състезател по математика с постижения на международно ниво. Завършил е магистратура по математика в СУ, ФМИ. Бил е асистент на първокурсници във ФМИ и е водил кръжок по програмиране за ученици в НПМГ."/>
        </div>
      </div>
    );
  }
}

class Home extends Component{
  render(){
    return (
      <div>
        <Carousel>
            <Carousel.Item>
              <img style={{maxHeight: 650}} alt="Лого" src={require('./images/logo.png')} />
              <Carousel.Caption>
                <div className = "container">
                  <h3>СИкадеми - иновативна подготовка по математика и информатика</h3>
                  <p>Курсове по състезателна математика за малки ученици.</p>
                  <p>Курсове за 5, 6 и 7 клас за подготовка за НВО и състезания по математика.</p>
                  <p>Курсове по математика - подготовки за национални и международни състезания за ученици от 8 до 12 клас.</p>
                  <p>Курс по програмиране за начинаещи.</p>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img style={{maxHeight: 650}} alt="Класна стая" src={require('./images/classroom1.jpg')} />
              <Carousel.Caption>
                <div className = "container">
                  <h3>Какво предлагаме?</h3>
                  <p>Малки групи (до 10 деца).</p>
                  <p>Млади и мотивирани преподаватели.</p>
                  <p>Избор за присъствено или дистанционно обучение в реално време на групата.</p>
                  <p>Възможно е включване в групите на ученици от цялата страна!</p>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img style={{maxHeight: 650}} alt="Състезатели" src={require('./images/logo.png')} />
              <Carousel.Caption>
                <div className = "container">
                  <h3 style={{color: 'black'}}>Нашите преподаватели са състезатели</h3>
                  <p style={{color: 'black'}}>Нашите преподаватели са дългогодишни успешни състезатели по математика и информатика, които имат богат опит в решаването на задачи.</p>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img style={{maxHeight: 650}} alt="Класна стая" src={require('./images/classroom1.jpg')} />
              <Carousel.Caption>
                <div className = "container">
                  <h3>Какво целим?</h3>
                  <p>Основната цел на преподавателите ни е да представят математиката и програмирането по интересен и достъпен начин за Вашите деца, да предизвикат интерес и да усъвършенстват уменията им, необходими за решаването на задачи.</p>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
          <div className = "Container">
          </div>
        </div>
    );
  }
}

class AboutUs extends Component{
  render(){
    return(
      <div>
        <div className='container'>
          <h2>Контакти</h2>
          <p>Телефон: +359 899856334</p>
          <p>E-mail: sicademymaths@gmail.com</p>
        </div>
        <div className='container'>
          <p>Адрес: Ж.К. Лозенец ул. Христо Смирненски номер 9</p>
          <MyMapComponent
            isMarkerShown
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAZlYPz9_lZvnhWC1XgW84UQu_X5IHUjNk&v=3.exp&libraries=geometry,drawing,places&language=bg"
            loadingElement={<div style={{ width: '100%', height: `500px` }} />}
            containerElement={<div style={{ width: '100%', height: `500px` }} />}
            mapElement={<div style={{ width: '100%', height: `500px` }} />}
          />
        </div>
      </div>
    );
  }
}

class Submition extends Component{
  constructor(props){
    super(props);
    this.state = {submition: 0}
  }
  render(){
    return (
      <div style={{overflow: 'auto', position: 'fixed', zIndex: 10, left: 0, top: 0, width: '100%', height: '100%', background: 'rgba(127, 127, 127, 0.5)', paddingTop: '100px'}}>
        <div className='container'>
          <Jumbotron style={{width:'80%', margin: 'auto'}}>
            <h3>Информация за курса</h3>
            <p>Преподавател: {this.teacherInfo()}</p>
            <p>Време на провеждане: {this.timeInfo()}</p>
            <p>Първо занятие: {this.props.course.begin_info}</p>
            <h3>Записване за курса</h3>
            {
              this.state.submition === 0 && this.props.course.free > 0 ? (
                <form onSubmit={this.submit.bind(this)}>
                  <div className="form-group">
                    <label htmlFor="kid_name">Имена на ученик/ученичка</label>
                    <input type="text" className='form-control' id='kid_name' placeholder="Име Презиме Фамилия" required/>
                  </div>
                  <div className="form-group">
                    <label htmlFor="parent_name">Имена на родител</label>
                    <input type="text" className='form-control' id='parent_name' placeholder="Име Презиме Фамилия" required/>
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email за обратна връзка</label>
                    <input type="email" className='form-control' id='email' placeholder="Email" required/>
                  </div>
                  <div className="form-group">
                    <label htmlFor="phone">Телефон за обратна връзка</label>
                    <input type="tel" className='form-control' id='phone' placeholder="+359 123456789" required/>
                  </div>
                  <button type="submit" className="btn btn-primary">Запиши ме</button>
                  <button type='button' className='btn btn-primary' onClick={()=>{this.props.close()}} style={{float: 'right'}}>Затвори</button>
                  <p>Кликайки бутона "Запиши ме" вие се съгласявате да съхраняваме вашите имена, телефон и e-mail по време на обучението и да ги използваме за цели на обучението.</p>
                </form>
             ) : ( null )
            }{
              this.state.submition !== 0 ? (
               <div>
                <h3>Заявката ви е изпратенa.</h3>
                <p>Очаквайте обратна връзка от нас скоро за да доизнясним детайлите по записването.</p>
                <button type='button' className='btn btn-primary' onClick={()=>{this.props.close()}}>Затвори</button>
              </div>
             ) : ( null )
            }{
              this.props.course.free <= 0 ? (
                <div>
                  <h3>Местата за този курс са изчерпани.</h3>
                  <button type='button' className='btn btn-primary' onClick={()=>{this.props.close()}}>Затвори</button>
                </div>
              ) : (null)
            }
          </Jumbotron>
        </div>
      </div>
    );
  }
  submit(e){
    e.preventDefault();
    submitForm(
      this.props.course.id,
      document.getElementById('kid_name').value,
      document.getElementById('parent_name').value,
      document.getElementById('email').value,
      document.getElementById('phone').value,
      ()=>{this.setState({submition: 1})}
    );
    return false;
  }
  teacherInfo(){
    let ans = "";
    for (let t of this.props.course.teachers){
      ans += t + ", ";
    }
    return ans.substring(0, ans.length-2);
  }
  timeInfo(){
    let ans=""
    for (let i=0; i<this.props.course.times.length; ++i){
      ans += this.props.course.times[i];
      if (i < this.props.course.times.length-1) ans+=', ';
    }
    return ans;
  }
}

class App extends Component{
  constructor(props){
    super(props);
    this.state = {activeKey: 1, submition: null, years: []};
    subscribeYears(
      (years_)=>{
        this.setState({activeKey: this.state.activeKey, submition: this.state.submition, years: years_});
      }, 
      (year)=>{}
    );
  }
  handleSelect(key){
    this.setState({activeKey: key, submition: null, years: this.state.years});
  }
  link(tab){
    if (tab==='Начало') this.handleSelect(1);
    if (tab==='Новини') this.handleSelect(2);
    if (tab==='Преподаватели') this.handleSelect(3);
    if (tab==='Контакти') this.handleSelect(4);
  }
  openSubmition(course){
    this.setState({activeKey: this.state.activeKey, submition: <Submition course={course} close={this.closeSubmition.bind(this)}/>, years: this.state.years})
  }
  closeSubmition(){
    this.setState({activeKey: this.state.activeKey, submition: null, years: this.state.years});
  }
  navs(){
    let ans = [];
    for (let i=0; i<this.state.years.length; ++i){
      ans.push( <NavItem key={i} eventKey={5+i}>{this.state.years[i].title}</NavItem> );
    }
    return ans;
  }
  opennedYear(){
    if (this.state.activeKey <= 4 || this.state.activeKey >= 5+this.state.years.length) return null;
    return (
      <CourseYear courseTypes={this.state.years[this.state.activeKey-5].courseTypes} openSubmition={this.openSubmition.bind(this)}/>
    );
  }
  render(){
    return (
      <div>
        <Navbar inverse collapseOnSelect onSelect={this.handleSelect.bind(this)}>
          <Navbar.Header>
            <Navbar.Brand>
              <a href="#brand" onClick={()=>{this.link.bind(this)('Начало')}}>SIcademy</a>
            </Navbar.Brand>
            <Navbar.Toggle />
          </Navbar.Header>
          <Navbar.Collapse>
            <Nav activeKey={this.state.activeKey}>
              <NavItem eventKey={1}>
                Начало
              </NavItem>
              <NavItem eventKey={3}>
                „Ключът към знанието“ - 2024
              </NavItem>
              <NavItem eventKey={2}>
                „Ключът към знанието“ - Архив
              </NavItem>
              <NavItem eventKey={4} href="#">
                Контакти
              </NavItem>
              {this.navs()}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <div>
          {this.state.activeKey===1 ? <Home linkcb={this.link.bind(this)}/> : null}
          {this.state.activeKey===2 ? <NewsList linkcb={this.link.bind(this)}/> : null}
          {this.state.activeKey===3 ? <SecondRound linkcb={this.link.bind(this)}/> : null}
          {this.state.activeKey===4 ? <AboutUs linkcb={this.link.bind(this)}/> : null}
          {this.opennedYear()}
        </div>
        {this.state.submition}
      </div>
    );
  }
}

export default App;
