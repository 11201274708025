import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './App.css';

class HTMLVis extends Component{
    render(){
        return (
            <div>
                {this.props.html === "" && this.props.instructions === 1 ?
                    <div>
                        <h3>Записване</h3>
                        <p>За да се запишете за курс изпълнете следните стъпки:</p>
                        <ol>
                            <li>Кликнете върху курса по-горе. Ще се отвори прозорче в което да попълните Вашите данни.</li>
                            <li>Попълнете Вашите данни и кликнете "Запиши ме".</li>
                            <li>След това, очаквайте да се свържем с Вас за потвърждение в рамките на 1-2 дни.</li>
                        </ol>
                    </div>:
                    null
                }
            </div>
        );
    }
    componentDidMount(){
        if (this.props.html !== "" || this.props.instructions === 0) ReactDOM.findDOMNode(this).innerHTML = this.props.html;
    }
}

export { HTMLVis };