import React, { Component } from 'react';
import { Jumbotron } from 'react-bootstrap';
import { subscribeNews, format } from "./connection.js"

class SecondRound extends Component {
  render() {
    return (
      <div className="container">
        <Jumbotron>
          <h3>II Математически турнир “Ключът към знанието”</h3>
          <p>Организатори на състезанието са <a href="https://buditel.softuni.bg/">Професионална гимназия "СофтУни БУДИТЕЛ"</a> и образователен център Сикадеми.</p>
          <h3>Обща информация:</h3>
          <p>Математически турнир “Ключът към знанието” е първото математическо състезание в България с видео решения на задачите. Състезанието е насочено към ученици с изявен интерес към математиката. Провежда се в два кръга, като във всеки следващ кръг задачите стават по-трудни.</p>
          <p><b>Първи кръг</b> ще се проведе на 20.04.2024 г. от 09.00 в <a href="https://buditel.softuni.bg/">Професионална гимназия "СофтУни БУДИТЕЛ"</a>.</p>
          <p><b>Втори кръг</b> ще се проведе в на 18.05.2024 г. от 09:00 в <a href="https://buditel.softuni.bg/">Професионална гимназия "СофтУни БУДИТЕЛ"</a>.</p>
          <p>Такса правоучастие – 25 лв за кръг, която ще се заплаща само по банков път.</p>
          <p>Темите за 4 и 7 клас могат да се разглеждат и като пробни изпити за съответните класове, които кандидатстват.</p>
          <p>Условия и отговори на задачите от миналата година : <a href="https://drive.google.com/drive/u/1/folders/1v8XO81seujjUyuNDnIBmkObwR8xZkGsF">I Математически турнир 2023 г.</a></p>
          <p>Видеорешения на задачите от миналата година : <a href="https://www.youtube.com/@SicademyBG/playlists">https://www.youtube.com/@SicademyBG/playlists</a></p>

          <h3>Регламент:</h3>
          <p>Участниците ще бъдат разпределени в 9 възрастови групи по класове - 3, 4, 5, 6, 7, 8, 9, 10 и 11+:</p>
          <p><div style={{color:"red", display:"inline"}}>Групи 3 и 4 клас:</div> Състезанието ще бъде във формат на приемен изпит за прием в математически гимназии след 4 клас. Задачите са разпределени в два модула, както следва: </p>
          <p>Модул 1 съдържа 15 задачи с избираем отговор / по 1 т. за всеки верен отговор/, и 5 задачи с отворен отговор / по 2 т. за верен отговор/.</p>
          <p>Модул 2 се състои от 2 задачи по извънкласния материал за съответния клас. Изисква се подробно описване на решението, и всяка задача носи по 10 точки при пълно и точно описание. </p>
          <p>Максимален брой точки – 45.</p>
          <p>Времетраене: за модул 1 - 60 минути, за модул 2 – 1 час и 30 минути</p>
          <p><div style={{color:"red", display:"inline"}}>Групи 5, 6 и 7 клас:</div> Състезанието ще бъде във формат НВО след 7 клас.</p>
          <p>Модул 1 съдържа 20 задачи, които са със структуриран отговор с четири възможности за отговор, от които само един е правилен;</p>
          <p>Модул 2 се състои от 3 задачи изискващи подробно описание. Две от задачите се оценяват максимално с по 12 точки, а третата - с 11.</p>
          <p>Максимален брой точки – 100.</p>
          <p>Времетраене: за модул 1 - 75 минути, за модул 2 – 1 час и 30 минути.</p>
          <p><div style={{color:"red", display:"inline"}}>Групи 8 и 9-11+ клас</div> – състезанието ще включва 6 подробни задачи.</p>
          <p>Всяка задача се оценява максимално с по 10 точки.</p>
          <p>Задачите включват материал от областите: Алгебра, Геометрия, Комбинаторика и Теория на числата. </p>
          <p>В групата 9-11+ клас могат да участват както ученици от 11 и 12 клас, така и всички любители на математическите предизвикателства / студенти, учители, ръководители на отбори/.</p>
          <p>За групата 9-11+ клас няма да бъдат включени задачи, които изискват изучаван в Университета материал, но всяко правилно прилагане на такива знания ще се приема за валидно. Също така задачите ще са съобразени с всички класове.</p>
          <p>Максимален брой точки – 60</p>
          <p>Времетраене - 3 часа и 30 минути.</p>
          <h3>Допускане до следващ кръг</h3>
          <p>За групите от 3 до 7 клас: За участие на 2 кръг  се допускат първите 50%  от всяка възрастова група.</p>
          <p>За групите от 8 до  11+ клас: Всеки участник, получил поне 15 точки.</p>
          <p><b>Записване:</b> Попълнете детайлите в посочената <a href="https://docs.google.com/forms/d/e/1FAIpQLSdmtHvzpaWKPk-tlEZvvoNXWCbvxUPc2m6UdIxKALjYqMpLlQ/viewform?usp=sf_link" target="blank_">гугъл форма</a>.</p>
          <p>До два работни дни от записването ще получите имейл с потвърждение, както и номер на банкова сметка за плащане на таксата. </p>
          <p>В състезанието нямат право на участие лица, пряко свързани с дейността на СИкадеми.</p>
          <p>За връзка с нас: sicademymaths@gmail.com или тел: 0899856334.</p>
          <p>Очакваме Ви!</p>
        </Jumbotron>
      </div>
    );
  }
}

export {SecondRound};