import openSocket from 'socket.io-client';

const socket = openSocket();

function subscribeNews(cb){
    socket.on('addn', (news) => {
        cb(news);
    });
    socket.emit('reqn');
}

function subscribeYears(cb, cb_add){
    socket.on('addy', (course)=>{
        cb_add(course);
    });
    socket.on('years', (years)=>{
        cb(years);
    })
    socket.emit('reqy');
}

function format(date){
    let d = new Date(date);
    let monthName = ["Януари", "Февруари", "Март", "Април", "Май", "Юни", "Юли", "Август", "Септември", "Октомври", "Ноември", "Декември"];
    return d.getDate() + " " + monthName[d.getMonth()] + " " + d.getFullYear();
}

function submitForm(course_id, student_name, parent_name, email, phone, cb){
    //console.log(student_name, parent_name, email, phone);
    socket.emit('submit', {course_id: course_id, student_name: student_name, parent_name: parent_name, email: email, phone: phone});
    cb();
    return false;
}

export { subscribeNews, subscribeYears, format, submitForm };